<template>
  <div class="d-flex justify-content-center" style="flex-direction: column">
    <!-- <button
      type="button"
      class="btn btn-secondary btn-sm my-3"
      v-if="!visibility"
      @click="visibility = true"
    >
      {{ $trans("form-input.modifica") }}
      {{ $trans("form-input.indirizzo-spedizione") | lowercase }}
    </button> 
      v-else-->
    <div class="mb-1"><strong>Altro indirizzo di spedizione?</strong></div>
    <address-list
      :confirm="confirm"
      :for_cart="false"
      :client="client"
      :addresses="client.addresses"
      v-model="id_indirizzo"
      @nazioneChanged="nazioneChanged"
      :total-changed="totalChanged"
    ></address-list>
    <div v-if="isLoading" class="overlay no-select">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddressList from "./AddressList.vue";

export default {
  name: "EditShippingAddress",
  components: { AddressList },
  props: {
    client: Object,
    confirm: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      id_indirizzo: null,
      visibility: false,
      totalChanged: {
        changed: false,
      },
      suppl: null,
    };
  },
  watch: {
    id_indirizzo: function (newVal) {
      this.indirizzoChanged(newVal);
    },
    isLoading: function (value) {
      document.documentElement.style.overflowY = value ? "hidden" : "auto";
    },
  },
  mounted() {
    axios
      .get(
        "/api/v1/cart/calc-shipping?city=" +
          this.client.citta +
          "&cap=" +
          this.client.cap +
          "&metodo_evasione=" +
          "NOWAIT" +
          "&tipo_spedizione=" +
          "SP" +
          "&order_id=" +
          ""
      )
      .then((response) => {
        this.suppl =
          parseFloat(
            document
              .querySelector("#costo_spedizione")
              .innerHTML.replace(",", ".")
          ) - response.data.data.shipping;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    nazioneChanged(val) {
      //console.log(e);
    },
    indirizzoChanged(val) {
      this.isLoading = true;
      let address = this.client.addresses.filter((row) => {
        return row.id == val;
      });
      address = address.length ? address[0] : this.client;
      document.querySelector("#newAddress").value = JSON.stringify(address);

      axios
        .get(
          "/api/v1/cart/calc-shipping?city=" +
            address.citta +
            "&cap=" +
            address.cap +
            "&metodo_evasione=" +
            "NOWAIT" +
            "&tipo_spedizione=" +
            "SP" +
            "&order_id=" +
            ""
        )
        .then((response) => {
          const tot_sel = document.querySelector("#tot_ordine");
          const shipment_default = parseFloat(
            tot_sel.getAttribute("data-shipment").replace(",", ".")
          );
          const shipment_new = parseFloat(response.data.data.shipping);
          this.totalChanged = {
            changed: shipment_default !== shipment_new,
            original: shipment_default,
            new: shipment_new,
          };
          document.querySelector("#newShipment").value = shipment_new;
          const temp =
            parseFloat(
              tot_sel.getAttribute("data-shipment").replace(",", ".")
            ) +
            parseFloat(
              tot_sel.getAttribute("data-packaging").replace(",", ".")
            );
          const vat =
            temp -
            parseFloat(
              tot_sel.getAttribute("data-shipmentNoVat").replace(",", ".")
            );
          const ship_sel = document.querySelector("#costo_spedizione");
          ship_sel.innerHTML =
            new Intl.NumberFormat("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(shipment_new + this.suppl) + "&nbsp;&euro;";
          tot_sel.innerHTML =
            new Intl.NumberFormat("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
              parseFloat(ship_sel.innerHTML.replace(",", ".")) +
                parseFloat(
                  document
                    .querySelector("#tot_netto")
                    .innerHTML.replace(",", ".")
                )
            ) + "&nbsp;&euro;";
        })
        .catch((error) => {
          CustomSwal({
            title: "Errore",
            html: error.response.data.errors.join("<br><br>"),
            confirmButtonText: "Ok",
            icon: "error",
          }).then(() => {});
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  filters: {
    lowercase(value) {
      return value.toLowerCase();
    },
  },
};
</script>

<style scoped>
</style>
