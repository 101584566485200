<template>
  <div style="width: 100%">
    <div
      v-if="totalChanged.changed"
      class="
        alert alert-warning
        d-flex
        align-items-center
        d-flex
        justify-content-between
      "
      role="alert"
    >
      <div class="d-flex align-items-center" style="gap: 10px">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="currentColor"
          class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
          viewBox="0 0 16 16"
          role="img"
          aria-label="Warning:"
        >
          <path
            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
          />
        </svg>
        <strong>{{ $trans("form-input.attenzione") }}</strong>
        <span>{{ $trans("checkout.spedizione-cambiata") }}</span>
      </div>
      <button type="button" class="btn btn-secondary" @click="showTable">
        {{ $trans("checkout.visualizza") }}
      </button>
    </div>
    <div class="addresses-list">
      <ul class="shipping-address__list">
        <li
          class="addresses-list__item card address-card"
          v-bind:class="{
            selected: indirizzo === 'fatturazione',
            'from-single-order': !for_cart,
          }"
        >
          <label class="shipping-address__item-label pointer-cursor no-select">
            <span class="shipping-address__input input-radio">
              <span class="input-radio__body">
                <input
                  class="input-radio__input"
                  name="shipping-address"
                  required
                  type="radio"
                  v-model="indirizzo"
                  value="fatturazione"
                  @click="$emit('nazioneChanged', client)"
                />
                <span class="input-radio__circle"></span>
              </span>
            </span>
            <span class="shipping-address__item-title">{{
              $trans("checkout.sel-indirizzo")
            }}</span>
          </label>
          <div class="address-card__body">
            <div class="address-card__name">
              {{ client.ragione_soc }}
            </div>
            <div class="address-card__row">
              {{ client.indirizzo }}<br />
              {{ client.cap }}, {{ client.citta }} {{ client.provincia.trim() ? "(" + client.provincia.trim() + ")" : "" }}
              <br />
              {{ client.nazione }}
            </div>
            <div class="address-card__row" v-if="client.telefono">
              <div class="address-card__row-title">
                {{ $trans("form-input.telefono") }}
              </div>
              <div class="address-card__row-content">
                {{ client.telefono }}
              </div>
            </div>
          </div>
        </li>
        <li
          class="addresses-list__item card address-card"
          v-for="address in addresses"
          v-bind:class="{
            selected: indirizzo === address.id,
            'from-single-order': !for_cart,
          }"
        >
          <label class="shipping-address__item-label pointer-cursor no-select">
            <span class="shipping-address__input input-radio">
              <span class="input-radio__body">
                <input
                  :value="address.id"
                  class="input-radio__input"
                  name="shipping-address"
                  required
                  type="radio"
                  v-model="indirizzo"
                  @click="$emit('nazioneChanged', address)"
                />
                <span class="input-radio__circle"></span>
              </span>
            </span>
            <span class="shipping-address__item-title">{{
              $trans("checkout.sel-indirizzo")
            }}</span>
          </label>
          <div class="address-card__body">
            <div class="address-card__name">
              {{ address.ragione_soc }}
            </div>
            <div class="address-card__row">
              {{ address.indirizzo }}<br />
              {{ address.cap }}, {{ address.citta }} {{ address.provincia.trim() ? "(" + address.provincia.trim() + ")" : "" }}
              <br />
              {{ address.nazione }}
            </div>
            <div class="address-card__row" v-if="address.telefono">
              <div class="address-card__row-title">
                {{ $trans("form-input.telefono") }}
              </div>
              <div class="address-card__row-content">
                {{ address.telefono }}
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div
        class="addresses-list__item addresses-list__item--new"
        style="flex-direction: row; gap: 5px"
      >
        <button
          v-if="confirm && !confirmed"
          class="btn btn-secondary btn-sm"
          type="button"
          v-on:click="showNewAddress = !showNewAddress"
        >
          {{ $trans("checkout.aggiungi-nuovo") }}
        </button>
        <button
          v-if="confirm && !confirmed"
          class="btn btn-secondary btn-sm"
          type="button"
          :disabled="!indirizzo"
          v-on:click="confirmAddress"
        >
          {{ $trans("common.conferma") }}
        </button>
      </div>
    </div>
    <div class="card-divider" v-show="showNewAddress"></div>
    <new-address
      class="mt-3 mb-3"
      v-show="showNewAddress"
      :from-checkout="for_cart"
      @newAddress="onNewAddress"
    ></new-address>
  </div>
</template>

<script>
import NewAddress from "./new-address.vue";

export default {
  name: "AddressList",
  components: {
    NewAddress,
  },
  props: {
    value: String | Number,
    for_cart: Boolean,
    client: Object,
    addresses: Array,
    totalChanged: {
      type: Object,
      default: function () {
        return {
          changed: false
        }
      }
    },
    confirm: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showNewAddress: false,
      indirizzo: this.value,
      confirmed: false,
    };
  },
  watch: {
    indirizzo: function (newVal) {
      this.$emit("input", newVal);
    },
  },
  methods: {
    onNewAddress(e) {
      this.$emit("newAddress", e);
    },
    confirmAddress() {
      // nascondo i radio
      var elems = document.querySelectorAll(
        ".shipping-address__list > li > label"
      );
      for (var i = 0; i < elems.length; i++) {
        elems[i].classList.add("hidden");
      }
      // nascondo la riga dei pulsanti
      document
        .querySelector(".addresses-list__item--new")
        .classList.add("hidden");
      // nascondo le opzioni non selezionate
      elems = document.querySelectorAll(
        ".shipping-address__list > li:not(.selected)"
      );
      for (var i = 0; i < elems.length; i++) {
        elems[i].classList.add("hidden");
      }
      this.confirmed = true;
    },
    showTable() {
      document.querySelector(".checkout__totals").scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      if (this.totalChanged.changed) {
        const sel = document.querySelector(".checkout__totals").classList;
        sel.add("highlight-border");
        setTimeout(() => {
          sel.remove("highlight-border");
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
</style>
