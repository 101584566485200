<template>
  <form
      id="new-address-form"
      role="form"
      method="POST"
      novalidate
      class="custom-validation"
      data-toggle="validator"
      @submit="addAddress"
  >
    <div class="loader" v-show="loading">LOADING...</div>
<!--    {{address}}-->
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-name-newadd">
          {{ $trans("form-input.ragione-sociale") }}
          <sup>*</sup>
        </label>
        <input
            type="text"
            class="form-control"
            id="checkout-name-newadd"
            name="ragione_soc"
            v-model="ragione_soc"
            required
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="checkout-country-newadd"
        >{{ $trans("form-input.nazione") }} <sup>*</sup></label
        >
        <select
            id="checkout-country-newadd"
            class="form-control"
            name="nazione"
            v-model="nazione"
        >
          <option v-for="item in nations">{{ item }}</option>
        </select>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-9">
        <label for="checkout-street-address-newadd"
        >{{ $trans("form-input.indirizzo") }} <sup>*</sup></label
        >
        <input
            type="text"
            class="form-control"
            id="checkout-street-address-newadd"
            name="indirizzo"
            v-model="indirizzo"
            required
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
        <div
            class="invalid-feedback"
            style="display: block"
            v-if="!isAddressValid"
        >
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
      <div class="form-group col-md-3" :class="{'d-none': !isItalia}">
        <label :for="'checkout-postcode-newadd-' + this.addressId"
        >{{ $trans("form-input.cap") }} <sup>*</sup></label>
        <div class="select2-wrapper">
          <select
              :id="'checkout-postcode-newadd-' + this.addressId"
              name="cap"
              aria-label="Seleziona CAP"
              v-model="cap"
              required
              class="form-control"
              :data-value="address ? address.cap : null"
          ></select>
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
      <div class="form-group col-md-3" :class="{'d-none': isItalia}">
        <label>{{ $trans("form-input.cap") }} <sup>*</sup></label>
        <div class="select2-wrapper">
          <input
              id="estero-cap"
              v-model="cap"
              class="form-control"
              type="text"
              onkeydown="regexInput(event, new RegExp('^[a-zA-Z0-9 -]+$'))"
              :maxlength="isItalia ? 5 : 10"
          />
<!--          onkeydown="regexInput(event, /^[0-9]+$/i)"-->
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-7" v-if="isItalia">
        <label :for="'checkout-city-newadd-' + this.addressId"
        >{{ $trans("form-input.city") }} <sup>*</sup></label
        >
        <div class="select2-wrapper">
          <select
              :id="'checkout-city-newadd-' + this.addressId"
              name="citta"
              aria-label="Seleziona città"
              v-model="citta"
              required
              class="form-control"
              v-bind:disabled="!cap || loadingCities"
              :data-value="address ? address.citta : null"
              onchange="setLoading(false)"
          >
            <option
                v-for="city in cities"
                :value="city.citta"
                v-bind:key="city.citta"
            >
              {{ city.citta }}
            </option>
          </select>
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
      <div class="form-group col-md-7" v-if="!isItalia">
        <label>{{ $trans("form-input.city") }} <sup>*</sup></label>
        <div class="select2-wrapper">
          <input v-model="citta"
                 class="form-control"
                 type="text">

        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
      <div class="form-group col-md-5">
        <label for="checkout-state-newadd">
          {{ $trans("form-input.provincia") }}
          <sup v-if="!nazione || nazione === 'ITALIA'">*</sup>
        </label>
        {{provincia}}
        <input
            type="text"
            class="form-control"
            id="checkout-state-newadd"
            :maxlength="!nazione || nazione === 'ITALIA' || isItalia ? 2 : -1"
            name="provincia"
            v-model="provincia"
            required
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-tel-newadd">{{
            $trans("form-input.telefono")
          }}</label>
        <input
            type="tel"
            class="form-control"
            id="checkout-tel-newadd"
            name="telefono"
            v-model="telefono"
            maxlength="15"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="checkout-cell-newadd">{{
            $trans("form-input.cellulare")
          }}</label>
        <input
            type="tel"
            class="form-control"
            id="checkout-cell-newadd"
            name="cellulare"
            v-model="cellulare"
            maxlength="15"
        />
      </div>
    </div>
    <button type="submit" class="btn btn-primary" v-bind:disabled="!isValid">
      {{ $trans("form-input.salva-indirizzo") }}
    </button>
  </form>
</template>

<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "new-address",
  props: {
    addressId: {
      required: false,
      type: Number,
      default: 0,
    },
    address: {
      required: false,
      type: Object,
      default: null,
    },
    fromCheckout: {
      required: false,
      type: Boolean,
      default: false,
    },
    fromOrder: Array,
    reload: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      ragione_soc: null,
      nazione: '',
      indirizzo: null,
      cap: null,
      citta: null,
      provincia: null,
      telefono: null,
      cellulare: null,
      cities: [],
      loadingCities: false,
      loadingAddress: false,
      nations: [],
      isItalia: false,
    };
  },
  mounted() {
    window.regexInput = this.regexInput;
    window.setLoading = this.setLoading;
    console.log(this.address)
    // $("#e6").select2('data', {id: '1049', text: 'MyLabel'});

    axios.get('/api/v1/nations').then((res) => {
      this.nations = res.data.data[0];
    });

    if (this.address != null) {
      this.loadingAddress = true;
      this.parseAddress();
    }

    if (this.fromOrder != null) {
      this.loadingAddress = true;
      this.parseAddressFromOrder(this.fromOrder);
    }

    const self = this;


    $("#checkout-postcode-newadd-" + this.addressId).on("change", function (evt) {
      console.log(evt)
      self.cap = this.value;
    });

    $("#checkout-city-newadd-" + this.addressId).select2({
      language: "it",
      width: "100%",
    });

    $("#checkout-city-newadd-" + this.addressId).on("change", function (evt) {
      self.citta = this.value;
      this.loading = false;
    });

    // setTimeout(() => {
    //   $("#checkout-postcode-newadd-" + this.addressId).select2("data", {
    //     id: $("#checkout-postcode-newadd-" + this.addressId).attr('data-value'),
    //     text: $("#checkout-postcode-newadd-" + this.addressId).attr('data-value'),
    //   });
    // }, 500);
    $(document).ajaxStart(() => this.loading = true);
    $(document).ajaxStop(() => this.loading = false);
    setTimeout(() => {
      this.setLoading(false);
    }, 1000);
  },
  methods: {
    setLoading(flag){
      this.loading = flag;
    },
    getCap() {
      const nation = !this.address || Object.keys(this.address).length === 0 //this.nazione != document.querySelector('#checkout-country-newadd').value
          ? this.nazione
          : document.querySelector('#checkout-country-newadd').value;
      console.log('loading', nation)
      this.selectBox.select2({
        minimumInputLength: 3,
        language: "it",
        width: "100%",
        ajax: {
          url: "/api/v1/cap",
          dataType: "json",
          data: function (params) {
            return {
              q: params.term,
              nation
            };
          },
          processResults: function (data) {
            console.log('end loading')
            return {
              results: data.data.map((el) => {
                return {
                  id: el.cap,
                  text: el.cap,
                };
              }),
            };
          },
        },
      });

      // const $option = $(`<option selected>${this.selectBox.attr('data-value')}</option>`).val(this.selectBox.attr('data-value'));
      // this.selectBox.append($option).trigger('change');
    },
    loadCities: function () {
      const cap = this.cap || this.address || this.address.cap || document.querySelector('#estero-cap').value;
      this.loadingCities = true;
      console.log('TEST', this.cap, document.querySelector('#estero-cap').value, this.address)
      axios
          .get("/api/v1/city?q=" + cap + "&nation=" + this.nazione)
          .then((res) => {
            this.cities = res.data.data;

            if (!this.loadingAddress) {
              if (this.cities.length === 1) {
                this.citta = this.cities[0].citta;
                this.provincia = this.cities[0].provincia;
              } else {
                this.citta = null;
                this.provincia = null;
              }
            } else {
              this.loadingAddress = false;
            }
          })
          .finally(() => {
            this.loadingCities = false;
          });
    },
    parseAddressFromOrder(order) {
      console.log(order);

    },
    parseAddress: function () {
      const capData = {
        id: this.address.cap,
        text: this.address.cap,
      };

      const option = new Option(capData.text, capData.id, true, true);
      $("#checkout-postcode-newadd-" + this.addressId)
          .append(option)
          .trigger("change");

      this.ragione_soc = this.address.ragione_soc;
      this.nazione = this.address.nazione;
      this.indirizzo = this.address.indirizzo;
      this.cap = this.address.cap;
      this.citta = this.address.citta;
      this.provincia = this.address.provincia;
      this.telefono = this.address.telefono;
      this.cellulare = this.address.cellulare;

      $("#checkout-postcode-newadd-" + this.addressId).trigger({
        type: "select2:select",
        params: {
          data: capData,
        },
      });
    },
    addAddress: function (e) {
      e.preventDefault();
      axios
          .post("/api/v1/client/address/add", {
            address_id: this.address != null ? this.address.id : null,
            ragione_soc: this.ragione_soc,
            indirizzo: this.indirizzo ? this.indirizzo.trim() : this.indirizzo,
            nazione: this.nazione,
            cap: this.cap,
            citta: this.citta,
            provincia: this.provincia,
            telefono: this.telefono,
            cellulare: this.cellulare,
          })
          .then((res) => {
            if (this.reload) {
              window.location.reload();
            }
            this.$emit("newAddress", res);
          })
          .catch((error) => {
            alert(error.response.data.errors.join());
          });
    },
    regexInput(event, pattern, callback = null) {
      const key = event.key;

      const ctrlKey = event.ctrlKey || event.metaKey; // ctrlKey per Windows, metaKey per Mac
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab", "Enter"];
      if (ctrlKey || allowedKeys.includes(key)) {
        return true;
      }

      if (!pattern.test(key)) {
        event.preventDefault();
        return false;
      }
      if (callback) {
        callback();
      }
      return true;
    }
  },
  computed: {
    selectBox: function () {
      return $("#checkout-postcode-newadd-" + this.addressId);
    },
    isValid: function () {
      return (
          this.ragione_soc &&
          this.indirizzo &&
          this.cap &&
          this.citta &&
          (
              this.provincia || (this.nazione !== 'ITALIA' && this.nazione !== '')
          ) &&
          this.nazione &&
          this.isAddressValid
      );
    },
    isAddressValid: function () {
      if (!this.indirizzo) {
        return true;
      }
      return /\d/.test(this.indirizzo) && this.indirizzo.trim().length > 3 && (this.indirizzo.trim().match(/\s/g) || []).length > 0;
      // return /^(?=[^a-z\n]*[a-z])[A-Za-z.-.\s.\\..,°.-]*[0-9][A-Za-z.-.\s.\\..,°.-]*$/.test(this.indirizzo);
      // let text = !this.indirizzo.match(/\D/g)
      //     ? ''
      //     : this.indirizzo.match(/\D/g).join('').trim().replace(/[^a-z]/gi, '');
      // let digits = this.indirizzo.replace(/\D/g, "");
      // return !text || !digits;
    },
  },
  watch: {
    nazione: function (v) {
      this.isItalia = v.toLowerCase().startsWith('it') || !v || v === '-';
      console.log(v, this.isItalia);
      if (this.isItalia) {
        this.getCap();
        this.cap = '';
      }
    },
    cap: function () {
      this.loadCities();
    },
    citta: function (v) {
      const filtered = this.cities.filter((el) => {
        return el.citta === this.citta;
      });
      if (filtered.length > 0) {
        this.provincia = filtered[0].provincia;
      }
      setTimeout(() => {
        if(v) {
          this.loading = false;
        }
      }, 2000);
    },
  },
};
</script>
